.PriceAdjustComponentCon1 {
  box-sizing: border-box;
  background: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  max-width: 500px;
  padding: 12px;
  display: flex;
}

.PriceAdjustComponentCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.PriceAdjustComponentCon13 {
  border-left: 1px solid #6d6d6d;
  border-right: 1px solid #6d6d6d;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding-left: 2px;
  padding-right: 2px;
  display: inline-flex;
}

.PriceAdjustComponentConText {
  color: #6d6d6d;
  letter-spacing: .28px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.PriceAdjustComponentCon14 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.PriceAdjustComponentCon14Text {
  color: #2b2b2b;
  letter-spacing: .28px;
  word-wrap: break-word;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

.price-slider-container {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 12px;
  font-family: Arial, sans-serif;
  display: flex;
}

.slider-track {
  background: #ccc;
  border-radius: 4px;
  height: 8px;
  position: relative;
}

.slider-range {
  z-index: 0;
  background: #6d6d6d;
  border-radius: 4px;
  height: 100%;
  position: absolute;
}

.slider-handle {
  cursor: pointer;
  z-index: 0;
  background: #6d6d6d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background .2s;
  position: absolute;
  transform: translate(-50%, -50%);
}

.slider-handle:hover {
  background: #4a4a4a;
}

.input-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.input-group {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  display: flex;
  position: relative;
}

.input-label {
  color: #6d6d6d;
  opacity: 0;
  font-size: 12px;
  transition: opacity .3s;
  position: absolute;
  top: -20px;
}

.input-group-input[type="text"] {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 80px;
  padding: 6px;
  font-size: 14px;
}

.input-group-input[type="text"]:focus + .input-label {
  opacity: 1;
}

.SearchStartUpComponentCon1 {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin: 0 auto;
  padding: 12px;
  display: flex;
}

.SearchStartUpComponentCon1BackGround {
  background: linear-gradient(#3a7d44 5%, #3a7d441a 100%);
}

.SearchStartUpComponentCon2 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  max-width: 500px;
  display: flex;
}

.SearchStartUpComponentCon3 {
  align-self: stretch;
  align-items: flex-end;
  gap: 6px;
  display: flex;
}

.SearchStartUpComponentCon4 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 10px;
  transition: padding-bottom .3s ease-in-out, width .3s ease-in-out;
  display: flex;
}

.SearchStartUpComponentCon5 {
  background: #fff;
  border-radius: 10px;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: inline-flex;
  box-shadow: 0 0 4px #00000080;
}

.SearchStartUpComponentCon5Input {
  color: #6d6d6d;
  word-wrap: break-word;
  flex: 1 1 0;
  width: 100%;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
}

.SearchStartUpComponentCon6 {
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  min-height: 30px;
  padding: 0 12px;
  transition: width .3s ease-in-out;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.SearchStartUpComponentCon6B {
  box-sizing: border-box;
  color: #2b2b2b;
  word-wrap: break-word;
  cursor: pointer;
  padding-right: 12px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.SearchStartUpComponentCon6BHide {
  display: none;
}

.SearchStartUpComponentCon7 {
  opacity: 0;
  background: #fff;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  max-height: 0;
  margin-bottom: 32px;
  padding: 12px 12px 24px;
  transition: max-height .3s ease-in-out, opacity .3s ease-in-out;
  display: none;
}

.SearchStartUpComponentCon7Display {
  opacity: 1;
  max-height: 1600px;
}

.SearchStartUpComponentCon4WithPadding {
  padding-bottom: 6px;
}

.SearchStartUpComponentCon8 {
  border-bottom: .5px solid #6d6d6d;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 6px;
  display: flex;
}

.SearchStartUpComponentCon8B {
  border: none;
}

.SearchStartUpComponentCon9 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: inline-flex;
}

.SearchStartUpComponentCon10 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  min-width: 150px;
  display: inline-flex;
}

.SearchStartUpComponentCon10 input {
  pointer-events: none;
  opacity: .4;
}

.SearchStartUpComponentCon10Clear input {
  pointer-events: all;
  opacity: 1;
}

.SearchStartUpComponentCon10Label {
  color: #2b2b2b;
  letter-spacing: .28px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.SearchStartUpComponentCon11 {
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-left: 12px;
  display: inline-flex;
}

.SearchStartUpComponentCon12 {
  background: #fff;
  border: 1px solid #6d6d6d;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  padding: 2px 12px;
  display: flex;
}

.SearchStartUpComponentCon12Text {
  color: #6d6d6d;
  letter-spacing: .36px;
  word-wrap: break-word;
  min-width: 120px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.SearchStartUpComponentCon16 {
  align-self: stretch;
}

.SearchStartUpComponentCon14 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  display: flex;
}

.SearchStartUpComponentCon15 {
  background: #f4c542;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 24px;
  display: inline-flex;
  overflow: hidden;
}

.Button {
  color: #2b2b2b;
  letter-spacing: .4px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
}

.SearchStartUpComponentCategoryCon1 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.SearchStartUpComponentCategoryCon1Label {
  color: #2b2b2b;
  letter-spacing: .28px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.SearchStartUpComponentCategoryCon2 {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.SearchStartUpComponentCategoryCon3 {
  background: var(--app-white, #fff);
  color: var(--app-gray-medium, #6d6d6d);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  box-shadow: 0 2px 4px #00000040;
}

.SearchStartUpComponentCategoryCon3Active {
  background: var(--app-white, #fff);
  color: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  box-shadow: inset 0 0 4px #00000080;
}

.SearchStartUpComponentCon13 {
  flex-wrap: wrap;
  place-content: flex-start space-between;
  align-self: stretch;
  align-items: flex-start;
  row-gap: 6px;
  display: none;
}

.SearchStartUpComponentCon13B {
  align-items: center;
  gap: 12px;
  display: flex;
}

.SearchStartUpComponentCon13Number {
  color: #6d6d6d;
  letter-spacing: .28px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.SearchStartUpComponentCon13Text {
  color: #2b2b2b;
  letter-spacing: .28px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

.select-wrapper {
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  position: relative;
}

.select-wrapper-2 {
  width: 120px;
  position: relative;
}

.selected-options {
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0;
  display: flex;
}

.selected-option {
  background: var(--app-gray-soft, #6d6d6d);
  border: solid .5px var(--app-gray-medium, #6d6d6d);
  color: var(--app-charcoal-dark, #fff);
  letter-spacing: .28px;
  border-radius: 3px 20px 20px 3px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 2px 2px 2px 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.selected-option .remove {
  cursor: pointer;
  width: 17px;
  height: 100%;
  color: var(--app-charcoal-dark);
  letter-spacing: .28px;
  background: #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  display: flex;
}

.search-input {
  border: 1px solid var(--app-gray-medium);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
}

.option-list {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-height: 200px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 2px 10px #0000001a;
}

.option-list-for-bottom-list {
  max-height: 120px;
}

.option-list.active {
  z-index: 1;
  display: block;
}

.option-item {
  cursor: pointer;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .28px;
  padding: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.option-item:hover {
  background-color: var(--app-gray-soft, #6d6d6d);
}

.create-option {
  cursor: pointer;
  color: var(--app-orange, #3a7d44);
  letter-spacing: .28px;
  padding: 5px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.create-option:hover {
  text-decoration: underline;
}

.created-tag {
  color: var(--app-orange, #3a7d44);
  background: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
}

.SearchListingsResultsContianer {
  background: var(--app-white);
  border: solid 1px var(--app-gray-soft);
  scroll-snap-type: y mandatory;
  border-radius: 6px;
  flex-wrap: wrap;
  place-content: flex-start center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  min-width: 200px;
  max-width: 750px;
  max-height: 75vh;
  padding: 12px;
  scroll-padding-top: 16px;
  scroll-padding-bottom: 16px;
  transition: height .3s;
  display: none;
  overflow: hidden scroll;
}

.SearchListingsResultsContianer > * {
  scroll-snap-align: start;
}

.SearchListingsResultsContianerForSnap {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  display: flex;
}

.SearchListingsResultsContianerTrigerLoadMore {
  width: 100%;
  height: 1px;
  display: flex;
}

.SearchStartUpComponentSelectForFilter {
  box-sizing: border-box;
  z-index: 10;
  background: #0000007d;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.SearchStartUpComponentSelectForFilterDisp {
  display: flex;
}

.SearchStartUpComponentSelectForFilterCon2 {
  box-sizing: border-box;
  z-index: 10;
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 500px;
  max-height: 300px;
  padding: 10px;
  display: flex;
  overflow: hidden scroll;
}

.SearchStartUpComponentSelectForFilterCon3 {
  border-bottom: .5px solid #6d6d6d;
  flex-wrap: wrap;
  place-content: flex-end space-between;
  align-self: stretch;
  align-items: flex-end;
  row-gap: 24px;
  padding-bottom: 6px;
  display: flex;
}

.SearchUicSearchFilterResultsButton {
  background: var(--app-white, #fff);
  color: #2b2b2b;
  letter-spacing: .24px;
  word-wrap: break-word;
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.SearchUicSearchFilterResultsButtonActive {
  background: var(--app-green);
  color: #fff;
}

.ActivateOnlyBusinessSeachingButton {
  background: var(--app-white, #fff);
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 6px;
  display: flex;
  box-shadow: 1px 2px 4px #00000040;
}

.ActivateOnlyBusinessSeachingButtonActive {
  background: var(--app-green, #3a7d44);
  box-shadow: inset 0 0 4px #00000080;
}

.SearchStartUpComponentFilterButtonClose {
  color: #2b2b2b;
  letter-spacing: .24px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}
/*# sourceMappingURL=index.c752d3fb.css.map */
