/* Start Silder Component */

.PriceAdjustComponentCon1 {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  gap: 6px;
  background: #fff;
}

.PriceAdjustComponentCon2 {
  display: flex;
  /* height: 17px; */
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.PriceAdjustComponentCon13 {
  align-self: stretch;
  padding-left: 2px;
  padding-right: 2px;
  border-left: 1px #6d6d6d solid;
  border-right: 1px #6d6d6d solid;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.PriceAdjustComponentConText {
  color: #6d6d6d;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

.PriceAdjustComponentCon14 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.PriceAdjustComponentCon14Text {
  flex: 1 1 0;
  color: #2b2b2b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

/* Main container */
.price-slider-container {
  display: flex;
  width: 100%;
  padding: 12px;
  /* max-width: 400px; */
  /* margin: 20px auto; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Arial, sans-serif;
}

/* Slider bar */
.slider-track {
  position: relative;
  height: 8px;
  background: #ccc;
  border-radius: 4px;
}

/* Active range on the slider */
.slider-range {
  position: absolute;
  height: 100%;
  background: #6d6d6d;
  border-radius: 4px;
  z-index: 0;
}

/* Slider handles */
.slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #6d6d6d;
  border-radius: 50%;
  cursor: pointer;
  z-index: 0;
  transform: translate(-50%, -50%);
  transition: background 0.2s;
}

.slider-handle:hover {
  background: #4a4a4a;
}

/* Input fields */
.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.input-label {
  position: absolute;
  top: -20px;
  font-size: 12px;
  color: #6d6d6d;
  opacity: 0;
  transition: opacity 0.3s;
}

.input-group-input[type="text"] {
  width: 80px;
  padding: 6px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.input-group-input[type="text"]:focus + .input-label {
  opacity: 1;
}

/* Start All Other serach Css */

.SearchStartUpComponentCon1 {
  width: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  gap: 12px;
  box-sizing: border-box;
}
.SearchStartUpComponentCon1BackGround {
  background: linear-gradient(180deg, #3a7d44 5%, rgba(58, 125, 68, 0.1) 100%);
}

.SearchStartUpComponentCon2 {
  align-self: stretch;
  max-width: 500px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.SearchStartUpComponentCon3 {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  align-self: stretch;
}

.SearchStartUpComponentCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  transition: padding-bottom 0.3s ease-in-out, width 0.3s ease-in-out;
}

.SearchStartUpComponentCon5 {
  align-self: stretch;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  border-radius: 10px;
  /* border: 0.5px #6d6d6d solid; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.SearchStartUpComponentCon5Input {
  width: 100%;
  flex: 1 1 0;
  color: #6d6d6d;
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.SearchStartUpComponentCon6 {
  display: flex;
  padding: 0px 12px 0px 12px;
  min-height: 30px;
  align-items: center;
  align-self: stretch;
  background: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  /* gap: 12px; */
  transition: width 0.3s ease-in-out;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.SearchStartUpComponentCon6B {
  box-sizing: border-box;
  padding-right: 12px;
  /* gap: 12px; */

  display: flex;
  color: #2b2b2b;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
}
.SearchStartUpComponentCon6BHide {
  display: none;
}

.SearchStartUpComponentCon7 {
  display: none;
  align-self: stretch;
  padding: 12px;
  padding-bottom: 24px;
  margin-bottom: 32px;
  background: white;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  /* overflow: hidden; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  /* Initial state: hidden and collapsed */
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.SearchStartUpComponentCon7Display {
  max-height: 1600px; /* Adjust to the maximum height of the content */
  opacity: 1;
}

.SearchStartUpComponentCon4WithPadding {
  padding-bottom: 6px;
}

.SearchStartUpComponentCon8 {
  align-self: stretch;
  /* height: 55px; */
  padding-bottom: 6px;
  border-bottom: 0.5px #6d6d6d solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}
.SearchStartUpComponentCon8B {
  border: none;
}

.SearchStartUpComponentCon9 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
}

.SearchStartUpComponentCon10 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  min-width: 150px;
  width: 100%;
}
.SearchStartUpComponentCon10 input {
  pointer-events: none; /* Prevent user interaction */
  opacity: 0.4;
}
.SearchStartUpComponentCon10Clear input {
  pointer-events: all;
  opacity: 1;
}

.SearchStartUpComponentCon10Label {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

.SearchStartUpComponentCon11 {
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

.SearchStartUpComponentCon12 {
  padding-left: 12px;
  min-width: 200px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px #6d6d6d solid;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SearchStartUpComponentCon12Text {
  min-width: 120px;
  color: #6d6d6d;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.36px;
  word-wrap: break-word;
}

.SearchStartUpComponentCon16 {
  align-self: stretch;
  /* height: 176px; */
}

.SearchStartUpComponentCon14 {
  align-self: stretch;
  /* height: 36px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

.SearchStartUpComponentCon15 {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #f4c542;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.Button {
  color: #2b2b2b;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.4px;
  word-wrap: break-word;
}

.SearchStartUpComponentCategoryCon1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.SearchStartUpComponentCategoryCon1Label {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

.SearchStartUpComponentCategoryCon2 {
  display: flex;
  padding: 0px 6px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
}

.SearchStartUpComponentCategoryCon3 {
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SearchStartUpComponentCategoryCon3Active {
  display: inline-flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.SearchStartUpComponentCon13 {
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
}
.SearchStartUpComponentCon13B {
  display: flex;
  align-items: center;
  gap: 12px;
}

.SearchStartUpComponentCon13Number {
  color: #6d6d6d;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

.SearchStartUpComponentCon13Text {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

/* Start Custom select */
.select-wrapper {
  position: relative;
  min-width: 150px;
  width: 100%;
  max-width: 300px;
  /* margin-bottom: 50px; Ensure there is space at the bottom for options list */
}
.select-wrapper-2 {
  position: relative;
  width: 120px;
  /* margin-bottom: 50px; Ensure there is space at the bottom for options list */
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0;
}

.selected-option {
  display: flex;
  padding: 2px 2px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 3px 20px 20px 3px;
  background: var(--app-gray-soft, #6d6d6d);
  border: solid 0.5px var(--app-gray-medium, #6d6d6d);

  color: var(--app-charcoal-dark, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.selected-option .remove {
  display: flex;
  /* margin-left: 5px; */
  /* padding: 0px 6px; */
  height: 100%;
  width: 17px;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
  background: white;
  cursor: pointer;
  color: var(--app-charcoal-dark);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.28px;
}

.search-input {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--app-gray-medium);
  border-radius: 4px;
  box-sizing: border-box;
}

.option-list {
  position: absolute;
  top: 100%; /* Position the list below the search input */
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1;
}
.option-list-for-bottom-list {
  max-height: 120px;
}

.option-list.active {
  display: block;
  z-index: 1;
}

.option-item {
  padding: 8px;
  cursor: pointer;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.option-item:hover {
  background-color: var(--app-gray-soft, #6d6d6d);
}

.create-option {
  /* color: #007bff; */
  cursor: pointer;
  /* font-weight: bold; */
  padding: 5px;

  color: var(--app-orange, #3a7d44);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}
.create-option:hover {
  text-decoration: underline;
}
.created-tag {
  color: var(--app-orange, #3a7d44);
  font-size: 12px;
  background: white;
  border-radius: 3px;
  font-weight: 300;
  /* margin-left: 5px; */
}

/* Start Results Container */

.SearchListingsResultsContianer {
  display: none;
  padding: 12px;
  /* max-height: 500px; */
  max-height: 75vh;
  max-width: 750px;
  min-width: 200px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
  background: var(--app-white);
  border-radius: 6px;
  border: solid 1px var(--app-gray-soft);
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-padding-top: 16px;
  scroll-padding-bottom: 16px;
  transition: height 0.3s ease;
  /* scroll-snap-align: start; */
}

.SearchListingsResultsContianer > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}
.SearchListingsResultsContianerForSnap {
  max-width: 750px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  margin: 0 auto;
  box-sizing: border-box;
}

.SearchListingsResultsContianerTrigerLoadMore {
  display: flex;
  height: 1px;
  width: 100%;
}

/* Start Filter */
.SearchStartUpComponentSelectForFilter {
  display: none; /* Use Flexbox */
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 12px;
  justify-content: center; /* Center child vertically */
  align-items: center; /* Center child horizontally */
  gap: 24px; /* Optional for spacing */

  box-sizing: border-box;
  background: #0000007d;
  z-index: 10;
  margin: 0 auto;
}

.SearchStartUpComponentSelectForFilterDisp {
  display: flex;
}

.SearchStartUpComponentSelectForFilterCon2 {
  display: flex; /* Maintain its Flexbox layout */
  flex-direction: column; /* Stack its children vertically */
  align-items: flex-start; /* Optional: align children inside flex-start */
  gap: 12px; /* Spacing between children */
  max-width: 500px; /* Set width constraint */
  max-height: 300px;
  padding: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  background: #fff;
  z-index: 10;
  overflow: hidden;
  overflow-y: scroll;
}

.SearchStartUpComponentSelectForFilterCon3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  row-gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;

  align-items: flex-end;
  align-content: flex-end;
  align-self: stretch;
  flex-wrap: wrap;

  padding-bottom: 6px;
  border-bottom: 0.5px #6d6d6d solid;
}

/*  */
/*  */
.SearchUicSearchFilterResultsButton {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 3px;
  background: var(--app-white, #fff);

  color: #2b2b2b;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}
.SearchUicSearchFilterResultsButtonActive {
  /* border: solid 1px var(--app-green); */
  background: var(--app-green);
  color: white;
}

.ActivateOnlyBusinessSeachingButton {
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;

  background: var(--app-white, #fff);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.ActivateOnlyBusinessSeachingButtonActive {
  background: var(--app-green, #3a7d44);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset;
}

.SearchStartUpComponentFilterButtonClose {
  color: #2b2b2b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}
